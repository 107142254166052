
export default {
  components: {
    ErrorMessage: () => import('~/components/structure/ErrorMessage.vue'),
  },
  layout: 'error-layout',

  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 404,
      }),
    },
  },
}
