export default () => {
  let resizeTicking = false

  function screenResized() {
    window.$nuxt.$emit('screenResized')
  }

  function requestResizeTick() {
    clearTimeout(resizeTicking)
    resizeTicking = setTimeout(function () {
      screenResized()
    }, 100)
  }

  window.addEventListener('resize', requestResizeTick, { passive: true })
}
