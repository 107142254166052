import { gql } from 'graphql-tag'
import { ImageFragment, VideoEmbedFragment, VideoLocalFragment } from './parts'

export const DepartmentsFragment = gql`
  fragment DepartmentsFragment on DepartmentRelationResponseCollection {
    data {
      id
      attributes {
        name
        description
        image {
          ...ImageFragment
        }
        videoLocal {
          ...VideoLocalFragment
        }
        videoEmbed {
          ...VideoEmbedFragment
        }
      }
    }
  }
  ${ImageFragment}
  ${VideoEmbedFragment}
  ${VideoLocalFragment}
`
