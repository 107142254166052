
import CursorDragSVG from '@/components/svg/icon/CursorDragSVG.vue'
import CursorLinkSVG from '@/components/svg/icon/CursorLinkSVG.vue'
export default {
  components: {
    CursorDragSVG,
    CursorLinkSVG,
  },
  data() {
    return {
      observer: null,
      linkTl: null,
      dragTl: null,
      jumbolinkTl: null,
    }
  },
  computed: {
    enable() {
      if (this.$screen.sm && this.$screen.md && this.$screen.lg) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    $route() {
      if (this.enable) {
        this.linkTl.reverse()
        this.dragTl.reverse()
        this.jumbolinkTl.reverse()
      }
    },
    enable(val) {
      if (val) this.init()
      else this.disable()
    },
  },
  mounted() {
    if (this.enable) this.init()
  },
  methods: {
    init() {
      this.observer = this.$ScrollTrigger.observe({
        target: this.$parent.$el,
        type: 'pointer,wheel',
        onMove: (d) => {
          this.update(d.x, d.y)
        },
        onHoverEnd: () => {
          this.$gsap.to(this.$refs.customCursor, 0.1, {
            opacity: 0,
          })
          this.initCursor = false
        },
      })

      const cursor = this.$refs.customCursor
      const drag = this.$refs.drag.$el
      const jumbolink = this.$refs.jumbolink.$el

      const speed = 0.1
      const size = 96

      this.linkTl = this.$gsap.timeline({ paused: true }).to(cursor, { width: 64, height: 64, opacity: 0.1, duration: 0.2 })

      this.dragTl = this.$gsap.timeline({ paused: true }).to(cursor, { mixBlendMode: 'normal', width: size, height: size, duration: speed }).to(drag, { display: 'block', duration: 0.01 })

      this.jumbolinkTl = this.$gsap.timeline({ paused: true }).to(cursor, { mixBlendMode: 'normal', width: size, height: size, duration: speed }).to(jumbolink, { display: 'block', duration: 0.01 })

      // LINK
      this.$nuxt.$on('cursor-link-on', () => {
        if (this.enable) this.linkTl.play()
      })

      this.$nuxt.$on('cursor-link-off', () => {
        if (this.enable) this.linkTl.reverse()
      })

      // DRAG
      this.$nuxt.$on('cursor-drag-on', () => {
        if (this.enable) this.dragTl.play()
      })

      this.$nuxt.$on('cursor-drag-off', () => {
        if (this.enable) this.dragTl.reverse()
      })

      // JUMBO LINK
      this.$nuxt.$on('cursor-jumbolink-on', () => {
        if (this.enable) this.jumbolinkTl.play()
      })

      this.$nuxt.$on('cursor-jumbolink-off', () => {
        if (this.enable) this.jumbolinkTl.reverse()
      })
    },

    disable() {
      this.observer.kill()
      this.observer = null
    },

    update(x, y) {
      const cursor = this.$refs.customCursor
      const yTo = this.$gsap.quickTo(cursor, 'top', { duration: 0.1 })
      const xTo = this.$gsap.quickTo(cursor, 'left', { duration: 0.1 })

      if (!this.initCursor) {
        this.$gsap.to(cursor, { duration: 0.1, top: y, left: x, opacity: 1 })
        this.initCursor = true
      } else {
        xTo(x)
        yTo(y)
      }
    },
  },
}
