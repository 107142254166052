
import { marked } from 'marked'
import { mapGetters } from 'vuex'
// import Analytics from 'analytics'
// import googleAnalytics from '@analytics/google-analytics'
import BtnMain from './button/BtnMain.vue'
import BtnTertiary from './button/BtnTertiary.vue'
import CookiesModalSVG from '@/components/svg/cookies/CookiesModalSVG.vue'
import TimesSVG from '@/components/svg/icon/TimesSVG.vue'
import CookieBoxSVG from '@/components/svg/cookies/CookieBoxSVG.vue'
import CookieBoxClipSVG from '@/components/svg/cookies/CookieBoxClipSVG.vue'
import CookieModalClipSVG from '@/components/svg/cookies/CookieModalClipSVG.vue'
export default {
  components: {
    TimesSVG,
    BtnMain,
    CookieBoxSVG,
    BtnTertiary,
    CookiesModalSVG,
    CookieBoxClipSVG,
    CookieModalClipSVG,
  },
  data() {
    return {
      showPrompt: false,
      showModal: false,
      gtm: {},

      essential: {
        id: 'site',
      },
      analytics: {
        id: 'analytics',
        value: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      cookies: 'getAnalytics',
    }),
    body() {
      if (this.cookies.body) {
        return marked.parse(this.cookies.body)
      }
      return null
    },
  },
  watch: {
    showModal() {
      // const smoother = this.$ScrollSmoother.get()
      // this.showModal ? smoother.paused(true) : smoother.paused(false)
      this.showModal ? this.$gsap.to('body', { overflow: 'hidden' }) : this.$gsap.to('body', { overflow: 'auto' })
    },
  },
  created() {
    if (this.cookies) {
      this.$nuxt.$on('manage-cookies', () => {
        this.showModal = true
      })
    }
  },
  mounted() {
    if (this.$cookies.get(this.essential.id)) {
      // if essential, check analytics
      if (this.$cookies.get(this.analytics.id)) {
        this.startAnalytics()
      } else {
        // no analytics
        this.analytics.value = false
      }
    } else {
      this.showPrompt = true
    }
  },
  methods: {
    acceptCookies() {
      // called on accept
      this.setSiteCookie()
      this.setAnalyticsCookie()
      this.startAnalytics()
      this.showPrompt = false
    },

    saveCookies() {
      // save site cookie incase not already set (set from modal)
      if (!this.$cookies.get(this.essential.id)) {
        this.setSiteCookie()
        this.showPrompt = false
      }

      if (this.analytics.value) {
        // save analytics cookie (start analytics) if not already added
        if (!this.$cookies.get(this.analytics.id)) {
          this.setAnalyticsCookie()
          this.startAnalytics()
        }
        // remove analytics cookie (if exists)
      } else if (this.$cookies.get(this.analytics.id)) this.removeAnalyticsCookie()

      this.showModal = false
    },

    deleteCookies() {
      Object.keys(this.$cookies.getAll()).forEach((cookie) => {
        this.$cookies.remove(cookie)
      })
      this.reload()
    },

    startAnalytics() {
      this.analytics.value = true

      // gtm
      this.$gtm.init(this.cookies.GA4ID, {
        enabled: true,
        scriptDefer: true,
        autoInit: true,
        pageTracking: true,
      })
    },

    setSiteCookie() {
      this.$cookies.set(this.essential.id, true, {
        path: '/',
        expires: new Date(new Date().getTime() + 1000 * 3600 * 240 * 120),
      })
    },
    setAnalyticsCookie() {
      this.$cookies.set(this.analytics.id, true, {
        path: '/',
        expires: new Date(new Date().getTime() + 1000 * 3600 * 240 * 120),
      })
    },

    removeSiteCookie() {
      this.$cookies.remove(this.essential.id)
    },

    removeAnalyticsCookie() {
      this.$cookies.remove(this.analytics.id)
      this.reload()
    },

    reload() {
      window.location.reload()
    },
  },
}
