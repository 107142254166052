import { gql } from 'graphql-tag'
import { CookiesFragment, FooterFragment, SocialsFragment, ShareLinksFragment } from './fragments/structure.js'

export default gql`
  query Global {
    global {
      data {
        attributes {
          contact
          mainMenu {
            ...MenuFragment
          }
          socials {
            socials {
              ...SocialsFragment
            }
          }
          shareLinks {
            share_links {
              ...ShareLinksFragment
            }
          }
          analytics {
            ...CookiesFragment
          }
          footer {
            ...FooterFragment
          }
        }
      }
    }
  }

  ${CookiesFragment}
  ${SocialsFragment}
  ${ShareLinksFragment}
  ${FooterFragment}
`
