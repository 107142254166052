import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import introspectionResult from '~/fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResult,
})
export default (ctx) => {
  let token = ''
  token = ctx.app.$config.STRAPI_API_TOKEN ? `Bearer ${ctx.app.$config.STRAPI_API_TOKEN}` : `Bearer`
  const link = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
    }
    if (networkError) console.error(`[Network error]: ${networkError}`)
  })
  // const link = onError((error) => {
  //   console.warn(error)
  // })
  return {
    link,
    httpEndpoint: ctx.app.$config.SCHEMA_URL || 'http://localhost:1337/graphql',
    httpLinkOptions: {
      credentials: 'same-origin',
    },
    cache: new InMemoryCache({ fragmentMatcher }),
    getAuth: () => token,
  }
}
