import { gql } from 'graphql-tag'
import { DepartmentsFragment } from './fragments/departments.js'

export default gql`
  query Pinpoint {
    pinpoint {
      data {
        attributes {
          CTA
          departments {
            ...DepartmentsFragment
          }
        }
      }
    }
  }
  ${DepartmentsFragment}
`
