
export default {
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentComponent: '',
    }
  },
  computed: {
    currentProperties() {
      if (this.getComponent() === 'linkPart') return { link: this.link }
      return null
    },
  },
  methods: {
    getComponent() {
      if (this.el) return this.el
      if (Object.keys(this.link).length === 0) return 'button'
      return 'linkPart'
    },
  },
}
