
import CustomCursor from '@/components/structure/CustomCursor.vue'

export default {
  name: 'DefaultLayout',
  components: {
    CustomCursor,
  },
  data() {
    return {
      // preloader: true,
      // loader: true,
      // initCursor: false,
    }
  },
  // computed: {
  //   isProduction: () => {
  //     return process.env.nodeEnv === 'production'
  //   },
  // },
  mounted() {
    // this.$nuxt.$on('sectionsMounted', this.sectionsMounted)
    // // CSS ANIMS
    // // TODO: not working on build, style property is lost
    // const things = document.querySelectorAll('.animate-me, .slow-animate-me')
    // things.forEach((thing) => {
    //   Array.from(thing.children).forEach((el, index) => {
    //     el.style.setProperty('--animation-stagger', index)
    //   })
    // })
    this.$nuxt.$emit('go')

    // this.$nextTick(() => {

    // })
  },
  // beforeDestroy() {
  //   this.$nuxt.$off('sectionsMounted', this.sectionsMounted)
  // },
  // methods: {
  //   loaderComplete() {
  //     this.loader = false
  //     this.$nextTick(() => {
  //       console.log('layout go')
  //       this.$nuxt.$emit('go')
  //     })
  //   },
  //   sectionsMounted() {
  //     if (!this.loader) {
  //       console.log('layout go')
  //       this.$nuxt.$emit('go')
  //     } else {
  //       console.log('timeout')
  //       setTimeout(() => {
  //         this.preloader = false
  //       }, 1000)
  //     }
  //   },
  // },
}
