import { gql } from 'graphql-tag'

export const MenuItemFragment = gql`
  fragment MenuItemFragment on MenusMenuItemRelationResponseCollection {
    data {
      attributes {
        order
        title
        url
        target
        page {
          data {
            id
            attributes {
              title
              slug
            }
          }
        }
      }
    }
  }
`
