
import media from '~/mixins/media'

export default {
  name: 'WebPicture',

  mixins: [media],

  props: {
    alt: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      default: () => {},
    },
    max: {
      type: String,
      default: 'massive',
    },
    min: {
      type: String,
      default: 'small',
    },
    classname: {
      type: String,
      default: () => '',
    },
    stopLazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sizes: ['tiny', 'small', 'medium', 'large', 'xlarge', 'massive'],
    }
  },
  computed: {
    lazyLoadImg() {
      if (this.stopLazy) {
        return 'eager'
      } else {
        return this.$device.isMobile ? 'lazy' : 'eager'
      }
    },
    imageData() {
      return this?.image?.data?.attributes
    },
    outputSizes() {
      const minIndex = this.sizes.includes(this.min) ? this.sizes.indexOf(this.min) : 0
      const maxIndex = this.sizes.includes(this.max) ? this.sizes.indexOf(this.max) : this.sizes.length
      return this.sizes.slice(minIndex, maxIndex + 1)
    },
  },
  methods: {
    imageLoaded() {
      this.$emit('imageLoaded')
    },
  },
}
