
import TLSVG from '@/components/svg/btn/TLSVG.vue'
import TRSVG from '@/components/svg/btn/TRSVG.vue'
import BLSVG from '@/components/svg/btn/BLSVG.vue'
import BRSVG from '@/components/svg/btn/BRSVG.vue'
export default {
  components: {
    TLSVG,
    TRSVG,
    BLSVG,
    BRSVG,
  },
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
    // el: {
    //   type: String,
    //   default: 'button',
    // },
    disabled: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentComponent: '',
    }
  },
  computed: {
    currentProperties() {
      if (this.getComponent() === 'linkPart') return { link: this.link }
      return null
    },
  },
  methods: {
    getComponent() {
      if (this.el) return this.el
      if (Object.keys(this.link).length === 0) return 'button'
      return 'linkPart'
    },
  },
}
