import { gql } from 'graphql-tag'
import { MenuItemFragment } from './menu-item.js'

export const MenuFragment = gql`
  fragment MenuFragment on MenusMenuEntityResponse {
    data {
      attributes {
        title
        slug
        items {
          ...MenuItemFragment
        }
      }
    }
  }
  ${MenuItemFragment}
`
