
import CustomCursor from '@/components/structure/CustomCursor.vue'
import CookieConsent from '@/components/parts/CookieConsent.vue'
import PreLoader from '@/components/structure/PreLoader.vue'
import Loader from '@/components/structure/Loader.vue'
import ClipPaths from '@/components/svg/ClipPaths.vue'
export default {
  name: 'DefaultLayout',
  components: {
    CookieConsent,
    PreLoader,
    Loader,
    GridOverlay: () => import('@/components/structure/GridOverlay.vue'),
    ClipPaths,
    CustomCursor,
  },
  data() {
    return {
      preloader: true,
      loader: true,
      initCursor: false,

      riveLoaded: false,
      pageLoaded: false
    }
  },
  computed: {
    isProduction: () => {
      return process.env.NODE_ENV === 'production'
    },
    isReady() {
      return this.riveLoaded && this.pageLoaded
    },
  },
  watch: {
    isReady(val) {
      if (val) {
        this.ready()
      }
    },
  },
  mounted() {
    // console.log('layout mount')
    this.$nuxt.$on('pageMounted', this.pageMounted)
    // CSS ANIMS
    // TODO: not working on build, style property is lost
    // const things = document.querySelectorAll('.animate-me, .slow-animate-me')
    // things.forEach((thing) => {
    //   Array.from(thing.children).forEach((el, index) => {
    //     el.style.setProperty('--animation-stagger', index)
    //   })
    // })
  },
  beforeDestroy() {
    this.$nuxt.$off('pageMounted', this.pageMounted)
  },
  methods: {
    loaderComplete() {
      this.loader = false
      this.$nextTick(() => {
        this.$ScrollTrigger.refresh()
        this.$nextTick(() => {
          this.$nuxt.$emit('go')
        })
        // this.$nuxt.$emit('go')
      })
    },
    riveIsLoaded() {
      this.riveLoaded = true
    },
    pageMounted() {
      this.pageLoaded = true
    },

    ready() {
      this.$ScrollTrigger.refresh()
      if (this.loader) {
        this.$nextTick(() => {
          this.$refs.loaderRef.anim.play()
          this.preloader = false

        })
      } 
    }
  },
}
