

import { Rive , EventType, RiveEventType}from "@rive-app/canvas-lite";

export default {
  components: {},
  data() {
    return {
      anim: null,
    }
  },
  mounted() {
   this.anim = new Rive({
        src: "/rive/glitch.riv",
        canvas: this.$refs.logo,
        autoplay: false,
        // loop: false,
        stateMachines: "State Machine 1",
        onLoad: () => {
          this.$emit('rive-loaded')
          //this.anim.resizeDrawingSurfaceToCanvas();
        },
    });
    const self = this
    function onRiveEventReceived(riveEvent) {
      const eventData = riveEvent.data;
      if (eventData.type === RiveEventType.General && eventData.name === "stop") {
        self.onAnimComplete();
      } 
    }

    // Add event listener and provide callback to handle Rive Event
    this.anim.on(EventType.RiveEvent, onRiveEventReceived);

  },

  methods: {
    onAnimComplete() {
      this.$nextTick(() => {
        this.$emit('loaderComplete')
      })
    },
  },
}
